import { useEffect } from 'react'
import style from './SideBar.module.css'

export default function(props) {



	useEffect(() => {
		const handleScroll = () => {
			
		}

		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	
	const classes = [style.display]


	return (
		<div className={classes.join(' ')}>
			<div>&larr;&nbsp;&nbsp;Upstream</div>
			<div>Thames River</div>
			<div>Downstream&nbsp;&nbsp;&rarr;</div>
		</div>
	)
}