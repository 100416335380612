import SideBar from './components/SideBar'
import Header from './components/Header'
import Map from './components/Map'
import './App.module.css'
function App() {
	let mobile = false
	if(window.innerWidth < 767){
		mobile = true
	}
	return (
		<div>
			<SideBar />
			<Header/>
			<Map mobile={mobile}/>

		</div>
	)
}

export default App
