import { useState, useEffect } from 'react'
import style from './Header.module.css'
const images = require.context('../images', true)

export default function(props) {

	const [headerPinned, setHeaderPinned] = useState(false)

	useEffect(() => {
		const handleScroll = () => {
			const scrollY = window.scrollY
			if (scrollY > 10) {
				setHeaderPinned(true)
			} else {
				setHeaderPinned(false)
			}
		}

		window.addEventListener('scroll', handleScroll, { passive: true })

		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	
	const classes = [style.display]
	if (headerPinned) {
		classes.push(style.pinned)
	}

	return (
		<header className={classes.join(' ')}>
			<h1>Thames River Works<br/><span>Map of Contents</span></h1>
			<p>Scroll to travel up river <img src={images('./arrows-up-down-light.svg')}/></p>
		</header>
	)
}