import thunk from 'redux-thunk'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import thames from '../reducers/thames'

const initialState = {}
const reducers = combineReducers({
	thames
})

const middleware = [thunk]

const store = createStore(
	reducers,
	initialState,
	applyMiddleware(...middleware)
)
export default store
