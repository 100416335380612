export const SET_ARTICLE = 'SET_ARTICLE'
export const SET_ENTITY = 'SET_ENTITY'
export const CLEAR_SELECTION = 'CLEAR_SELECTION'

export const setArticle = (data) => (dispatch, getState) => {
	return dispatch({
		type: SET_ARTICLE,
		data: data,
	})
}

export const setEntity = (data) => (dispatch, getState) => {
	return dispatch({
		type: SET_ENTITY,
		data: data,
	})
}

export const clearSelection = (data) => (dispatch, getState) => {
	return dispatch({
		type: CLEAR_SELECTION,
		data: data,
	})
}
