import data from '../data/thames-river-works.json'

import {
	SET_ARTICLE,
	SET_ENTITY,
	CLEAR_SELECTION,
} from '../actions/'


export default function(
	state = {
		articles: data.articles,
		entities: data.entities,
		entityLabels: data.entityLabels,
		selectedArticles: [],
		selectedEntityType: undefined,
		selectedEntitySlug: undefined,
	},
	action
) {
	switch (action.type) {
	case SET_ARTICLE: {
		return {
			...state,
			selectedArticles: [action.data],
			selectedEntityType: undefined,
			selectedEntitySlug: undefined,
		}		
	}
	case SET_ENTITY: {
		return {
			...state,
			selectedArticles: action.data.articles,
			selectedEntityType: action.data.type,
			selectedEntitySlug: action.data.slug,
		}		
	}
	case CLEAR_SELECTION: {
		return {
			...state,
			selectedArticles: [],
			selectedEntityType: undefined,
			selectedEntitySlug: undefined,
		}		
	}
	default:
		return state
	}
}
