import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import TinySlider from 'tiny-slider-react'
import 'tiny-slider/dist/tiny-slider.css'

import style from './Map.module.css'
import londonlocations from './../data/geocodedgeojson.json'
const images = require.context('../images', true)

mapboxgl.accessToken = 'pk.eyJ1IjoiYW5lY2RvdGUxMDEiLCJhIjoiY2oxMGhjbmpsMDAyZzJ3a2V0ZTBsNThoMiJ9.1Ce55CnAaojzkqgfX70fAw'

// <img src={loadImage("someimage.png")} alt="" />

export default function(props) {

	const mapContainer = useRef(null)
	const map = useRef(null)
	const [ positions, setPositions ] = useState(null)

	const { articles } = useSelector((state) => state.thames)

	useEffect(() => {
		if (map.current) {
			return
		}
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/anecdote101/cl0zfm2cv005414o0schimo87',
			center: [-0.13, 51.4905],
			bearing: 90,
			zoom: 13.8,
			attributionControl: false,
			zoomControl: false,
			height: '100%',
			interactive: false
		})

		if(props.mobile !== true){
			map.current.fitBounds([
				[-0.28301722166463605, 51.466674948889136],
				[0.023017221667714693, 51.51431260696205]
			], {
				bearing: 90,
				duration: 0
			})
		}


		const geoJson = {
			type: 'FeatureCollection',
			features: []
		}

		Object.entries(articles).map((entry, i) => {
			const [ slug, article ] = entry
			geoJson.features.push({
				properties: {
					id: slug
				},
				geometry: {
					
					type: 'Point',
					coordinates: article.coordinates
				}
			})
		})
		setTimeout(e=>{
			map.current.resize()
		}, 1000)

		map.current.on('load', e=>{
			console.log('load')

			const pos = {}
			geoJson.features.forEach(feature=>{

				const point = map.current.project(feature.geometry.coordinates)	
				const slug = feature.properties.id
				pos[slug] = point
			})

			setPositions(pos)

			map.current.addSource('markers-source', {
				'type': 'geojson',
				'data': geoJson,
				'generateId': true
			})

			

			// map.current.on('mouseenter', 'markers', (e) => {
			// 	map.current.getCanvas().style.cursor = 'pointer'
			// 	if(e.features.length > 0){
			// 		if (projectId !== null && projectId !== undefined) {
			// 			map.current.setFeatureState(
			// 				{ source: 'markers-source', id: projectId },
			// 				{ hover: false }
			// 			)
			// 		}
			// 		projectId = e.features[0].id
			// 		map.current.setFeatureState(
			// 			{ source: 'markers-source', id: projectId },
			// 			{ hover: true }
			// 		)
			// 	}
			// })



			if(props.mobile !== true){
				map.current.addSource('locations-source', {
					'type': 'geojson',
					'data': londonlocations
				})
				map.current.addLayer({
					id: 'regions',
					type: 'heatmap',
					source: 'locations-source',
					paint: {
						'heatmap-weight': 4,
						'heatmap-intensity': 1,
						'heatmap-color': [
							'interpolate',
							['linear'],
							['heatmap-density'],
							0, 'rgba(103, 103, 131,0)',
							1, 'rgba(103, 103, 131,0.8)',

						],
						'heatmap-radius': 15
					}
				})
			}
			
			let circleSize = 20
			if(props.mobile){
				circleSize = 10
			}
			
			map.current.addLayer({
				'id': 'markers',
				'type': 'circle',
				'source': 'markers-source',
				'paint': {			
					'circle-color': [
						'case',
						['boolean', ['feature-state', 'hover'], false],
						'#ffb679',
						'#e27949'
					],
					'circle-radius': circleSize
				}
			})
		})
	}, [])


	return (
		<div className={style.map}>
	
			{Object.entries(articles).map((entry, i) => {

				const [ slug, article ] = entry

				if(!article.abstract){
					article.abstract = 'The development of London’s West India Docks, opened in 1802, made manifest the contemporary connections between culture, capitalism, and colonialism. A liminal space, the docks existed as a secure conduit for the importation of goods from the West Indies, most of which were produced by enslaved Africans. As such, they functioned as a threshold between the brutal realities of the plantation-based slave economy, and the polite world of the London merchant whose wealth derived from that economy. This collaborative article, which we wrote as two curators at the Museum of London, explores the lasting effects of that liminality, focusing on the aesthetic and spatial implications of the West India Docks’s environment, and the ways in which these persist in influencing the site and its communities today.'
				}
				const settings = {
					lazyload: true,
					mouseDrag: true,
					speed: 400
				}

				if (!positions) {
					return null
				}

				const position = positions[slug]
				let join = ''
				if(article.subtitle){
					join = ':'
				}
				return (
					<div style={{top: Math.round(position.y) + 'px', left: Math.round(position.x) + 'px'}} key={`article_${slug}`} className={[style[slug], style.article].join(' ')}>
						<div>
							<div className={style.circleTitle}>{article.locationName}</div>

							<h1>{article.title}{join} {article.subtitle}</h1>
							<h2>{article.author}</h2>
						</div>	
						<div className={style.imageContainer}>


							<TinySlider settings={settings}>
								{article.images && article.images.map((image, index) => {

									return (
										<div key={index} className={style.galleryImage} style={{ position: 'relative' }}>
											<img 
												alt="" 
												data-src={images(`./${image.filename}`)} 
												src={images(`./${image.filename}`)}
											/>
											<div dangerouslySetInnerHTML={{__html: image.caption}} className={style.caption}></div>
										</div>
									)
								})}
								
							</TinySlider>
							
						</div>
					
					</div>
				)
			})}
			<div ref={mapContainer} className={style.mapInner} />
		</div>
	)
}

